import React from "react";
import {
  BaseForm,
  Select,
  DateRangePicker,
  VALIDATION_TYPES,
} from "../../../../../components/Form";
import { getMessage } from "../../../../../lib/translator";
import { USER_ACTIVITY_LOGS_ENTITIES } from "../Table/userActivityConstants";
import { getStores } from "../../../../../lib/auth";
import API from "../../../../../lib/api";
import "./style.css";

export default class UserActivityFilters extends BaseForm {
  componentDidMount() {
    new API({ url: "/account-service/employee" })
      .get()
      .then(({ data }) => {
        this.setState({
          userNames: data.employee.reduce((userObj, user) => {
            userObj.push({
              text: user.name,
              value: user.id,
            });
            return userObj;
          }, []),
        });
      })
      .catch((error) => console.error(error));
    this.setState({
      stores: getStores().reduce((storeObj, store) => {
        storeObj.push({
          text: store.name,
          value: store.id,
        });
        return storeObj;
      }, []),
    });
  }
  handleSelectChange = (value) => {
    this.props.updateApiUrl(
      USER_ACTIVITY_LOGS_ENTITIES.find((entity) => entity.value === value).url
    );
    return value;
  };
  render() {
    const { SubmitButton, ClearButton } = this.buttons;
    const { Form } = this.components;
    return (
      <Form>
        <div className="form-fields user-activity">
          <Select
            label={getMessage("user.filters.user.activity.entity")}
            placeholder={getMessage(
              "user.filters.user.activity.entity.placeholder"
            )}
            name="entityType"
            options={USER_ACTIVITY_LOGS_ENTITIES}
            {...this.generateStateMappers({
              stateKeys: ["entityType"],
              defaultValue: USER_ACTIVITY_LOGS_ENTITIES[0].value,
              loseEmphasisOnFill: true,
              formatChange: (value) => this.handleSelectChange(value),
            })}
          />
          <Select
            label={getMessage("user.filters.user.activity.store.name")}
            placeholder={getMessage(
              "user.filters.user.activity.store.name.placeholder"
            )}
            name="storeName"
            options={this.state.stores}
            {...this.generateStateMappers({
              stateKeys: ["storeName"],
              loseEmphasisOnFill: true,
            })}
          />
          <Select
            label={getMessage("user.filters.user.activity.user.name")}
            placeholder={getMessage(
              "user.filters.user.activity.user.name.placeholder"
            )}
            name="userName"
            options={this.state.userNames}
            {...this.generateStateMappers({
              stateKeys: ["userName"],
              loseEmphasisOnFill: true,
            })}
          />
          <DateRangePicker
            label={getMessage("user.filters.user.activity.dateRange.heading")}
            {...this.generateStateMappers({
              stateKeys: ["dateRange"],
              validationType: VALIDATION_TYPES.ONSUBMIT,
              loseEmphasisOnFill: true,
            })}
            displayFormat="YYYY-MM-DD"
            startDatePlaceholder={getMessage(
              "user.filters.user.activity.startDate.placeholder"
            )}
            isOutsideRange={(date) => date > new Date()}
            endDatePlaceholder={getMessage(
              "user.filters.user.activity.endDate.placeholder"
            )}
          />
        </div>
        <SubmitButton>{getMessage("order.filters.submitText")}</SubmitButton>
        <ClearButton>
          {getMessage("order.filters.clearFiltersText")}
        </ClearButton>
      </Form>
    );
  }
}
